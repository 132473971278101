<i18n lang="json">
{
  "fr": {
    "open-sidebar": "Ouvrir le panneau"
  },
  "en": {
    "open-sidebar": "Open sidebar"
  }
}
</i18n>

<template>
  <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-black">
    <button
      type="button"
      class="px-4 border-r border-grey-700 text-grey-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden"
      @click="emit('click')">
      <span class="sr-only">{{ t("open-sidebar") }}</span>
      <PfIcon size="lg" fill-class="" stroke-class="stroke-current" :icon="MENU_ICON" aria-hidden="true" />
    </button>
    <div v-if="showTopBar" class="flex-1 px-4 flex justify-between">
      <div><!-- TODO: Barre de recherche --></div>
      <div class="ml-4 flex items-center md:ml-6">
        <ProfileMenu class="ml-4" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { defineEmits } from "vue";

import ProfileMenu from "@/components/app/profile-menu";
import MENU_ICON from "@/lib/icons/menu.json";

const { t } = useI18n();

const emit = defineEmits(["click"]);

const showTopBar = false;
</script>
