<template>
  <FormField
    :id="id"
    v-slot="{ hasErrorState }"
    :label="label"
    :description="description"
    :col-span-class="colSpanClass"
    :errors="errors"
    :disabled="disabled"
    is-checkbox>
    <input
      :id="id"
      :name="name"
      :checked="checked"
      :value="value"
      :required="required"
      :disabled="disabled"
      type="checkbox"
      class="h-4 w-4 rounded disabled:text-grey-500 disabled:bg-grey-100 dark:bg-grey-800 dark:disabled:text-grey-400 dark:disabled:bg-grey-700"
      :class="
        hasErrorState
          ? 'text-red-500 border-red-300 focus:ring-red-900 focus:border-red-500 dark:border-red-600'
          : 'text-primary-800 border-grey-300 focus:ring-primary-500 focus:border-primary-500 dark:border-grey-700'
      "
      :aria-invalid="hasErrorState"
      :aria-errormessage="hasErrorState ? `${id}-error` : null"
      :aria-describedby="description ? `${id}-description` : null"
      @input="$emit('input', $event.target.checked)" />
  </FormField>
</template>

<script>
import FormField, { commonFieldProps } from "../field/index";

export default {
  components: {
    FormField
  },
  props: {
    ...commonFieldProps,
    checked: Boolean,
    value: {
      type: [Boolean, String],
      default: false
    }
  }
};
</script>
