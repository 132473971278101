import { AccountLoginBadCredentials, AccountLoginUnconfirmed } from "@/lib/consts/problems";
import { URL_ROOT } from "@/lib/consts/urls";

import router from "@/lib/router";
import i18n from "@/lib/i18n";

import { Client } from "@/lib/helpers/client";
import { useNotificationsStore } from "@/lib/store/notifications";
import { useAuthStore } from "@/lib/store/auth";

async function login(username, password) {
  const { addError } = useNotificationsStore();

  const requestData = {
    username,
    password
  };

  let response = null;
  try {
    response = await Client.post("/account/login", JSON.stringify(requestData));
  } catch (error) {
    switch (error?.response?.data?.type) {
      case AccountLoginBadCredentials:
        addError(i18n.global.t("authentication-login-bad-credentials"));
        break;
      case AccountLoginUnconfirmed:
        addError(i18n.global.t("authentication-login-unconfirmed"));
        break;
      default:
        addError(i18n.global.t("authentication-login-error"));
    }

    return;
  }

  const { setClaims } = useAuthStore();
  setClaims(response.data);

  const path = router.currentRoute.value.query.returnPath || { name: URL_ROOT };
  router.push(path);
}

async function logout() {
  await Client.post("/account/logout");

  const { setClaims } = useAuthStore();
  setClaims(null);

  window.location.assign("/");
}

async function refresh() {
  const auth = useAuthStore();
  const wasLoggedIn = auth.isLoggedIn;

  try {
    const response = await Client.get("/account/claims");

    if (response.status === 200) {
      auth.setClaims(response.data);
    } else {
      auth.setClaims(null);
      if (wasLoggedIn) {
        window.location.assign("/");
      }
    }
  } catch (err) {
    auth.setClaims(null);
    if (wasLoggedIn) {
      window.location.assign("/");
    }
    return;
  }
}

export default {
  login,
  logout,
  refresh
};
