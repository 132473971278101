<i18n lang="json">
{
  "fr": {
    "hue": "Teinte"
  },
  "en": {
    "hue": "Hue"
  }
}
</i18n>

<template>
  <ColorPicker
    :visible-formats="['rgb']"
    default-format="rgb"
    alpha-channel="hide"
    :color="color || props.defaultColor"
    @color-change="updateColor">
    <template #hue-range-input-label>
      <span class="sr-only">{{ t("hue") }}</span>
    </template>
  </ColorPicker>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import { ColorPicker } from "vue-accessible-color-picker/unstyled";

const { t } = useI18n();

const props = defineProps({
  color: { type: String, default: "" },
  defaultColor: { type: String, default: "rgb(0 0 0)" }
});

function updateColor(eventData) {
  emit("colorChange", eventData.cssColor);
}

const emit = defineEmits(["colorChange"]);
</script>

<style lang="postcss">
/*
This style block is unscoped intentionally.
This is done to have a lower specificity for its selectors which in turn makes it easier to override their styles.
The specificity for `.vacp-color-space[data-v-76c97bd2]` is 20 while the specificity for `.vacp-color-space` is 10.
*/
.vacp-color-picker {
  @apply text-p3
  max-w-full;
  --vacp-color: hsl(
    calc(var(--vacp-hsl-h) * 360) calc(var(--vacp-hsl-s) * 100%) calc(var(--vacp-hsl-l) * 100%) / var(--vacp-hsl-a)
  );
  --vacp-focus-color: theme("colors.primary.700");
  --vacp-color-space-width: 300px;
  --vacp-spacing: 6px;
  --vacp-tiled-background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee),
    linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee);
  display: grid;
  grid-gap: var(--vacp-spacing);
  grid-template-columns: 1fr min-content;
  grid-template-areas:
    "color-space  color-space"
    "range-inputs copy-button"
    "color-inputs color-inputs";
}
.vacp-color-picker *,
.vacp-color-picker *::before,
.vacp-color-picker *::after {
  box-sizing: border-box;
}
.vacp-color-picker button::-moz-focus-inner {
  border: none;
  padding: 0;
}
.vacp-color-picker :focus {
  outline: 2px solid var(--vacp-focus-color);
}
.vacp-color-space {
  position: relative;
  grid-area: color-space;
  overflow: hidden;
  height: calc(var(--vacp-color-space-width) * 0.6);
  @apply rounded-md;
}
.vacp-color-space-thumb {
  --vacp-thumb-size: calc(var(--vacp-spacing) * 4);
  width: var(--vacp-thumb-size);
  height: var(--vacp-thumb-size);
  margin-left: calc(-1 * var(--vacp-thumb-size) / 2);
  margin-bottom: calc(-1 * var(--vacp-thumb-size) / 2);
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #000;
}
/*
1. Don’t fully remove a focus outline or border. This is important to maintain a focus style in Windows’ high contrast mode.
*/
.vacp-color-space-thumb:focus {
  outline-color: transparent; /* 1. */
  box-shadow: 0 0 0 1px #000, 0 0 0 3px var(--vacp-focus-color);
}
.vacp-range-input-label {
  --vacp-slider-track-width: calc(100% + 6px);
  --vacp-slider-track-height: calc(var(--vacp-spacing) * 3);
  --vacp-slider-thumb-size: calc(var(--vacp-slider-track-height) + var(--vacp-spacing));
  display: block;
}
.vacp-range-input-group {
  grid-area: range-inputs;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.vacp-range-input-group > :not(:first-child) {
  margin-top: var(--vacp-spacing);
}
.vacp-range-input,
.vacp-range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.vacp-range-input {
  display: block;
  width: var(--vacp-slider-track-width);
  height: var(--vacp-slider-track-height);
  margin-right: 0;
  margin-left: 0;
  margin-top: var(--vacp-spacing);
  margin-bottom: var(--vacp-spacing);
  padding: 0;
  border: none;
  background: none;
}
.vacp-range-input:focus {
  outline: none;
}
/* Resets one of these annoying custom focus styles in Firefox. */
.vacp-range-input::-moz-focus-outer {
  border: none;
}
/*
Range input: Tracks
*/
.vacp-range-input::-moz-range-track {
  display: block;
  box-sizing: border-box;
  height: var(--vacp-slider-track-height);
  border: none;
  border-radius: calc(var(--vacp-slider-track-height) / 2);
}
.vacp-range-input::-webkit-slider-runnable-track {
  width: var(--vacp-slider-track-width);
  height: var(--vacp-slider-track-height);
  border: none;
  border-radius: calc(var(--vacp-slider-track-height) / 2);
}
.vacp-range-input::-ms-track {
  width: var(--vacp-slider-track-width);
  height: var(--vacp-slider-track-height);
  border: none;
  border-radius: calc(var(--vacp-slider-track-height) / 2);
}
.vacp-range-input:focus::-moz-range-track {
  border: 1px solid var(--vacp-focus-color);
  outline: 2px solid var(--vacp-focus-color);
}
.vacp-range-input:focus::-webkit-slider-runnable-track {
  border: 1px solid var(--vacp-focus-color);
  outline: 2px solid var(--vacp-focus-color);
}
.vacp-range-input:focus::-ms-track {
  border: 1px solid var(--vacp-focus-color);
  outline: 2px solid var(--vacp-focus-color);
}
.vacp-range-input--alpha::-moz-range-track {
  background-image: linear-gradient(to right, transparent, var(--vacp-color));
}
.vacp-range-input--alpha::-webkit-slider-runnable-track {
  background-image: linear-gradient(to right, transparent, var(--vacp-color));
}
.vacp-range-input--alpha::-ms-track {
  background-image: linear-gradient(to right, transparent, var(--vacp-color));
}
.vacp-range-input--hue::-moz-range-track {
  background-image: linear-gradient(
    to right,
    /*   0° */ #f00 calc(100% * 0 / 360),
    /*  60° */ #ff0 calc(100% * 60 / 360),
    /* 120° */ #0f0 calc(100% * 120 / 360),
    /* 180° */ #0ff calc(100% * 180 / 360),
    /* 240° */ #00f calc(100% * 240 / 360),
    /* 300° */ #f0f calc(100% * 300 / 360),
    /* 360° */ #f00 calc(100% * 360 / 360)
  );
}
.vacp-range-input--hue::-webkit-slider-runnable-track {
  background-image: linear-gradient(
    to right,
    /*   0° */ #f00 calc(100% * 0 / 360),
    /*  60° */ #ff0 calc(100% * 60 / 360),
    /* 120° */ #0f0 calc(100% * 120 / 360),
    /* 180° */ #0ff calc(100% * 180 / 360),
    /* 240° */ #00f calc(100% * 240 / 360),
    /* 300° */ #f0f calc(100% * 300 / 360),
    /* 360° */ #f00 calc(100% * 360 / 360)
  );
}
.vacp-range-input--hue::-ms-track {
  background-image: linear-gradient(
    to right,
    /*   0° */ #f00 calc(100% * 0 / 360),
    /*  60° */ #ff0 calc(100% * 60 / 360),
    /* 120° */ #0f0 calc(100% * 120 / 360),
    /* 180° */ #0ff calc(100% * 180 / 360),
    /* 240° */ #00f calc(100% * 240 / 360),
    /* 300° */ #f0f calc(100% * 300 / 360),
    /* 360° */ #f00 calc(100% * 360 / 360)
  );
}
/*
Range input: thumbs
*/
.vacp-range-input::-moz-range-thumb {
  box-sizing: border-box;
  width: var(--vacp-slider-thumb-size);
  height: var(--vacp-slider-thumb-size);
  border: 3px solid #fff;
  border-radius: 50%;
  background-color: transparent;
  box-shadow: 0 0 0 1px #000;
  transform: rotate(0);
}
.vacp-range-input::-webkit-slider-thumb {
  width: var(--vacp-slider-thumb-size);
  height: var(--vacp-slider-thumb-size);
  margin-top: calc((var(--vacp-slider-track-height) - var(--vacp-slider-thumb-size)) / 2);
  border: 3px solid #fff;
  border-radius: 50%;
  background-color: transparent;
  box-shadow: 0 0 0 1px #000;
  transform: rotate(0);
}
.vacp-range-input::-ms-thumb {
  width: var(--vacp-slider-thumb-size);
  height: var(--vacp-slider-thumb-size);
  margin-top: 0;
  border: 3px solid #fff;
  border-radius: 50%;
  background-color: transparent;
  box-shadow: 0 0 0 1px #000;
  transform: rotate(0);
}
.vacp-copy-button {
  @apply hidden;
}
.vacp-color-inputs {
  grid-area: color-inputs;
  display: flex;
  align-items: center;
}
.vacp-color-inputs > :not(:first-child) {
  margin-left: var(--vacp-spacing);
}
.vacp-color-input-group {
  flex-grow: 1;
  display: flex;
}
.vacp-color-input-label {
  @apply relative 
  block 
  text-sm 
  font-medium 
  transition-colors 
  duration-200 
  ease-in-out 
  text-grey-700 
  flex-1 
  after:block
  after:absolute
  after:bottom-2
  after:left-2
  after:h-5 
  after:w-5
  after:rounded-full
  after:border-grey-300
  after:border
  after:bg-primary-500;

  &:not(:last-child) {
    @apply mr-1.5;
  }
}
.vacp-color-input-label:after {
  background-color: var(--vacp-color);
}
.vacp-color-input-label-text {
  @apply block mb-1;
}
.vacp-color-input {
  @apply relative 
  rounded-md 
  shadow-sm 
  flex-1 
  block 
  w-full 
  min-w-0
  pl-8 
  sm:text-sm 
  transition-colors 
  duration-200 
  ease-in-out 
  text-grey-900
  font-normal
  border-grey-300
  focus:outline-none
  focus:ring-primary-500 
  focus:border-primary-500 
  placeholder-grey-500;
}
</style>
