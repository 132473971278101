<i18n lang="json">
{
  "fr": {
    "open-user-menu": "Ouvrir le menu utilisateur"
  },
  "en": {
    "open-user-menu": "Open user menu"
  }
}
</i18n>

<template>
  <Menu as="div" class="ml-3 relative">
    <div>
      <MenuButton
        class="max-w-xs bg-black flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-primary-500">
        <span class="sr-only">{{ t("open-user-menu") }}</span>
        <img
          class="h-8 w-8 rounded-full"
          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          alt="" />
      </MenuButton>
    </div>
    <Transition v-bind="flyoutMenuTransition">
      <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white focus:outline-none">
        <!--TODO: Lien vers profil utilisateur -->
        <MenuItem>
          <span v-if="displayName" class="block px-4 py-2 text-sm text-grey-700">{{ displayName }}</span>
        </MenuItem>
        <MenuItem>
          <LogoutBtn class="block px-4 py-2" />
        </MenuItem>
      </MenuItems>
    </Transition>
  </Menu>
</template>

<script setup>
import { computed } from "vue";
import gql from "graphql-tag";
import { storeToRefs } from "pinia";
import { useQuery, useResult } from "@vue/apollo-composable";
import { useAuthStore } from "@/lib/store/auth";
import { useI18n } from "vue-i18n";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import LogoutBtn from "@/components/app/logout-btn";

const { t } = useI18n();

// Style de la transition
const flyoutMenuTransition = {
  enterActiveClass: "transition ease-out duration-100",
  enterFromClass: "transform opacity-0 scale-95",
  enterToClass: "transform opacity-100 scale-100",
  leaveActiveClass: "transition ease-in duration-75",
  leaveFromClass: "transform opacity-100 scale-100",
  leaveToClass: "transform opacity-0 scale-95"
};

// Manage login state
const { isLoggedIn } = storeToRefs(useAuthStore());

// Display profile name
const displayName = useDisplayName();

function useDisplayName() {
  const { result } = useQuery(
    gql`
      query GetUserProfile {
        me {
          id
          email
          profile {
            id
            firstName
            lastName
          }
        }
      }
    `,
    null,
    () => ({
      enabled: isLoggedIn.value
    })
  );

  const me = useResult(result, null, (data) => data.me);

  return computed(() => {
    if (!me.value) return null;

    if (me.value.profile) {
      const { firstName, lastName } = me.value.profile;
      const fullName = `${firstName || ""} ${lastName || ""}`;

      if (fullName.trim() !== "") {
        return fullName;
      }
    }

    return me.value.email;
  });
}
</script>
