export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "choose-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un fichier"])},
        "default-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposez ou choisissez un fichier"])},
        "drop-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposez le fichier pour téléverser"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement en cours..."])}
      },
      "en": {
        "choose-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose file"])},
        "default-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop or choose a file"])},
        "drop-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop file to upload"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])}
      }
    }
  })
}
