<template>
  <div class="w-full relative lg:flex lg:items-start lg:justify-between">
    <div class="min-w-0 flex flex-col items-start space-y-1">
      <slot name="title">
        <h2 class="text-2xl my-0 font-bold leading-7 pb-1 text-gray-900 sm:text-3xl sm:truncate">
          {{ props.title }}
        </h2>
      </slot>
      <slot name="bottom"></slot>
    </div>
    <div v-if="showActions" class="mt-5 flex lg:mt-0 lg:ml-4">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script setup>
import { defineProps, useSlots, computed } from "vue";

const props = defineProps({
  title: {
    type: String,
    default: ""
  }
});

const slots = useSlots();
const showActions = computed(() => !!slots.actions);
</script>
