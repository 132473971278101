<i18n lang="json">
{
  "fr": {
    "loading": "Chargement en cours..."
  },
  "en": {
    "loading": "Loading..."
  }
}
</i18n>

<template>
  <Transition v-bind="loadingTransition">
    <div
      v-if="props.loading"
      class="absolute z-20 inset-x-0 flex justify-center items-center"
      :class="isFullPage ? 'h-screen bg-gradient-to-b from-grey-100 to-white' : 'h-full bg-white'">
      <PfSpinner :loading-label="t('loading')" />
    </div>
    <slot v-else></slot>
  </Transition>
</template>

<script setup>
import { defineProps } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  loading: Boolean,
  isFullPage: Boolean
});

const loadingTransition = {
  mode: "out-in",
  enterActiveClass: "transition ease-out duration-150",
  enterFromClass: "transform opacity-0 -translate-y-5",
  enterToClass: "transform opacity-100 translate-y-0",
  leaveActiveClass: "transition duration-350 ease-[cubic-bezier(1,0.5,0.8,1)]",
  leaveFromClass: "transform opacity-100 translate-y-0",
  leaveToClass: "transform opacity-0 -translate-y-5"
};
</script>
