<template>
  <FormField
    :id="id"
    v-slot="{ hasErrorState }"
    :label="label"
    :description="description"
    :col-span-class="colSpanClass"
    :errors="errors"
    :disabled="disabled"
    :has-hidden-label="hasHiddenLabel">
    <div class="flex rounded-md shadow-sm relative">
      <span
        v-if="addOn || $slots.addOn"
        class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-grey-300 dark:border-grey-800 bg-grey-100 dark:bg-grey-800 text-grey-600 dark:text-grey-400 sm:text-sm">
        <slot name="add-on">{{ addOn }}</slot>
      </span>

      <div
        v-else-if="leadingIcon"
        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        :class="leadingIconColorClass">
        <Icon class="h-5 w-5" :icon="leadingIcon" aria-hidden="true" />
      </div>

      <input
        :id="id"
        :value="value"
        :type="inputType"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        :readonly="readonly || isDatepicker"
        :maxlength="maxlength"
        class="flex-1 block w-full min-w-0 sm:text-sm transition-colors duration-200 ease-in-out dark:bg-grey-900 disabled:bg-grey-100 disabled:text-grey-700 dark:disabled:bg-grey-700 dark:disabled:text-grey-300 read-only:bg-grey-100 dark:read-only:bg-grey-700 read-only:focus:ring-grey-500 read-only:focus:border-grey-500"
        :class="[
          addOn ? 'rounded-none rounded-r-md' : 'rounded-md',
          leadingIcon ? 'pl-10' : 'pl-3',
          isDatepicker && !disabled ? 'cursor-pointer' : '',
          hasErrorState
            ? 'text-red-900 border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500 dark:text-red-400 dark:border-red-800 dark:placeholder-red-600'
            : 'text-grey-900 border-grey-300 focus:ring-primary-500 focus:border-primary-500 placeholder-grey-500 dark:text-grey-200 dark:border-grey-700 dark:placeholder-grey-400'
        ]"
        :aria-label="hasHiddenLabel ? label : null"
        :aria-invalid="hasErrorState"
        :aria-errormessage="hasErrorState ? `${id}-error` : null"
        :aria-describedby="description ? `${id}-description` : null" />
    </div>
  </FormField>
</template>

<script>
import FormField, { commonFieldProps } from "../field/index";
import Icon from "../../icon";

export default {
  components: {
    FormField,
    Icon
  },
  props: {
    ...commonFieldProps,
    value: {
      type: [String, Number],
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    autocomplete: {
      type: String,
      default: "on"
    },
    addOn: {
      type: String,
      default: ""
    },
    inputType: {
      type: String,
      default: "text"
    },
    leadingIcon: {
      type: Object,
      default() {
        return null;
      }
    },
    leadingIconColorClass: {
      type: String,
      default: "text-grey-400"
    },
    maxlength: {
      type: String,
      default: ""
    },
    readonly: Boolean,
    isDatepicker: Boolean,
    hasHiddenLabel: Boolean
  }
};
</script>
