<i18n lang="json">
{
  "fr": {
    "title": "Page introuvable",
    "go-home": "Retourner à l'accueil"
  },
  "en": {
    "title": "Page not found",
    "go-home": "Go to home page"
  }
}
</i18n>

<template>
  <div class="mx-auto max-w-full w-sm px-section my-12 md:my-16">
    <h1 class="text-3xl">{{ t("title") }}</h1>
    <RouterLink class="pf-button pf-button--primary" :to="{ name: $consts.urls.URL_ROOT }">{{ t("go-home") }}</RouterLink>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>
