<template>
  <!-- Field container -->
  <div class="pf-form-field" :class="colSpanClass">
    <!-- Labels for text-like inputs -->
    <FormFieldLabel
      v-if="!isRadioOrCheck && !hasHiddenLabel"
      class="mb-1"
      :input-id="id"
      :label="label"
      :has-error-state="hasErrorState"
      :disabled="disabled" />

    <div :class="{ 'relative flex items-start': isRadioOrCheck }">
      <!-- Input -->
      <div :class="{ 'flex items-center h-5': isRadioOrCheck }">
        <slot :hasErrorState="hasErrorState"></slot>
      </div>

      <!-- Labels for radio or checkbox -->
      <div v-if="isRadioOrCheck" class="ml-3 text-sm h-remove-margin">
        <FormFieldLabel :input-id="id" :label="label" :has-error-state="hasErrorState" :disabled="disabled" />
        <div
          v-if="description || $slots.description"
          class="mt-1"
          :class="hasErrorState ? 'text-red-500 dark:text-red-400' : 'text-grey-500 dark:text-grey-400'">
          <slot name="description">
            <p v-if="description" :id="`${id}-description`" class="mb-0 text-p4 leading-none">
              {{ description }}
            </p>
          </slot>
        </div>
      </div>
    </div>

    <!-- Field footer -->
    <template v-if="!isRadioOrCheck">
      <div
        v-if="description || $slots.description"
        class="mt-2"
        :class="hasErrorState ? 'text-red-500 dark:text-red-400' : 'text-grey-500 dark:text-grey-400'">
        <slot name="description">
          <p v-if="description" :id="`${id}-description`" class="mb-0 text-p4 leading-none">
            {{ description }}
          </p>
        </slot>
      </div>
    </template>
    <template v-if="!isRadio">
      <transition name="fade">
        <div v-if="hasErrorState || $slots.feedback" class="mt-2 text-red-500 dark:text-red-400">
          <slot name="feedback">
            <p v-if="hasErrorState" :id="`${id}-error`" class="mb-0 text-sm">
              {{ errors[0] }}
            </p>
          </slot>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import FormFieldLabel from "./label";

export const commonFieldProps = {
  colSpanClass: {
    type: String,
    default: ""
  },
  id: {
    type: String,
    default: ""
  },
  name: {
    type: String,
    default: ""
  },
  label: {
    type: String,
    default: "",
    required: true
  },
  description: {
    type: String,
    default: ""
  },
  errors: {
    type: Array,
    default() {
      return [];
    }
  },
  required: Boolean,
  disabled: Boolean
};

export default {
  components: {
    FormFieldLabel
  },
  props: {
    ...commonFieldProps,
    isRadio: Boolean,
    isCheckbox: Boolean,
    hasHiddenLabel: Boolean
  },
  computed: {
    hasErrorState() {
      return this.errors && this.errors.length > 0;
    },
    isRadioOrCheck() {
      return this.isRadio || this.isCheckbox;
    }
  }
};
</script>
