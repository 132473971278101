<i18n lang="json">
{
  "fr": {
    "logout": "Déconnexion"
  },
  "en": {
    "logout": "Logout"
  }
}
</i18n>

<template>
  <PfButtonAction
    v-if="isLoggedIn"
    class="text-grey-600 hover:text-grey-900 focus:text-grey-900"
    btn-style="link"
    :label="t('logout')"
    data-test-id="logout"
    @click="logout" />
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import AuthenticationService from "@/lib/services/authentication";
import { useAuthStore } from "@/lib/store/auth";

const { t } = useI18n();

const { isLoggedIn } = storeToRefs(useAuthStore());
const logout = AuthenticationService.logout;
</script>
