import * as urls from "@/lib/consts/urls";
import NotFound from "@/views/NotFound.vue";

const anonymous = true;
const notConnected = true;

/*
meta: {  
  anonymous: false, // If true, does not require login
  notConnected: false // If true, the user need to be not connected to use this page
}
*/

export default [
  {
    path: "/",
    name: urls.URL_ROOT,
    redirect: { name: urls.URL_QUESTIONNAIRE_LIST }
  },
  {
    path: "/login",
    name: urls.URL_ACCOUNT_LOGIN,
    component: () => import("@/views/account/Login.vue"),
    meta: {
      anonymous,
      notConnected
    }
  },
  {
    name: urls.URL_ACCOUNT_CREATE,
    path: "/subscribe",
    component: () => import("@/views/account/Create.vue"),
    meta: {
      anonymous,
      notConnected
    }
  },
  {
    key: urls.URL_ACCOUNT_CONFIRM,
    path: "/confirm-email",
    component: () => import("@/views/account/Confirm.vue"),
    meta: {
      anonymous,
      notConnected
    }
  },
  {
    name: urls.URL_ACCOUNT_FORGOT_PASSWORD,
    path: "/forgot-password",
    component: () => import("@/views/account/ForgotPassword.vue"),
    meta: {
      anonymous,
      notConnected
    }
  },
  {
    name: urls.URL_ACCOUNT_RESET_PASSWORD,
    path: "/reset-password",
    component: () => import("@/views/account/ResetPassword.vue"),
    meta: {
      anonymous,
      notConnected
    }
  },

  {
    name: urls.URL_QUESTIONNAIRE_LIST,
    path: "/questionnaires",
    component: () => import("@/views/questionnaires/List.vue")
  },
  {
    name: urls.URL_QUESTIONNAIRE_CREATE,
    path: "/questionnaires/create",
    component: () => import("@/views/questionnaires/Create.vue")
  },
  {
    name: urls.URL_QUESTIONNAIRE_DETAILS,
    path: "/questionnaires/:questionnaireId",
    component: () => import("@/views/questionnaires/Details.vue")
  },
  {
    name: urls.URL_QUESTIONNAIRE_EDIT,
    path: "/questionnaires/:questionnaireId/edit",
    component: () => import("@/views/questionnaires/Edit.vue")
  },
  {
    name: urls.URL_QUESTIONNAIRE_IMPORT,
    path: "/questionnaires/:questionnaireId/import",
    component: () => import("@/views/questionnaires/Import.vue")
  },

  {
    path: "/consultation/:questionnaireSlug",
    name: urls.URL_CONSULTATION_INDEX,
    component: () => import("@/views/consultation/Index.vue"),
    meta: {
      anonymous
    }
  },
  {
    path: "/consultation/:questionnaireSlug/merci",
    name: urls.URL_CONSULTATION_CONFIRMATION,
    component: () => import("@/views/consultation/Confirmation.vue"),
    meta: {
      anonymous
    }
  },
  {
    name: urls.URL_REPORT_LIST,
    path: "/reports",
    component: () => import("@/views/reports/List.vue")
  },
  {
    name: urls.URL_REPORT_CREATE,
    path: "/reports/create",
    component: () => import("@/views/reports/Create.vue")
  },
  {
    name: urls.URL_REPORT_EDIT,
    path: "/reports/edit/:reportId",
    component: () => import("@/views/reports/Edit.vue")
  },

  {
    path: "/rapport/:slug",
    name: urls.URL_REPORT_VIEW,
    component: () => import("@/views/reports/View.vue"),
    meta: {
      anonymous
    }
  },

  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
    meta: {
      anonymous
    }
  }
];
