<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="fixed z-40 inset-0 overflow-y-auto" @close="closeModal">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" v-bind="overlayTransition">
          <DialogOverlay class="fixed inset-0">
            <div class="absolute inset-0 bg-grey-500/75 pointer-events-none"></div>
          </DialogOverlay>
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" v-bind="modalTransition">
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div v-if="props.icon" class="mx-auto flex items-center justify-center text-grey-500 mb-3 sm:mb-5">
                <PfIcon size="2xl" fill-class="" stroke-class="stroke-current" :icon="props.icon" aria-hidden="true" />
              </div>
              <div class="text-center">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 my-0">
                  <slot name="title" :closeModal="closeModal">
                    {{ props.title }}
                  </slot>
                </DialogTitle>
                <div class="mt-2">
                  <slot name="description" :closeModal="closeModal">
                    <p class="text-sm text-gray-500">{{ props.description }}</p>
                  </slot>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <slot name="actions" :closeModal="closeModal">
                <PfButtonAction
                  class="w-full bg-emerald-500 hover:bg-emerald-600 focus:bg-emerald-600"
                  label="Ok"
                  @click="closeModal" />
              </slot>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { defineProps, defineExpose, ref } from "vue";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";

const props = defineProps({
  title: { type: String, default: null },
  description: { type: String, default: null },
  icon: { type: Object, default: null }
});

// Ouverture / fermeture
const isOpen = ref(false);
const openModal = () => {
  // Hack: Allow time to dropdown menu to close to avoid glitch in transition
  setTimeout(() => (isOpen.value = true), 100);
};
const closeModal = () => {
  isOpen.value = false;
};

// Méthodes et props accessible par le component parent
defineExpose({ closeModal, openModal, isOpen });

// Style de transitions
const overlayTransition = {
  enter: "ease-out duration-300",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "ease-in duration-200",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0"
};

const modalTransition = {
  enter: "ease-out duration-300",
  enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
  enterTo: "opacity-100 translate-y-0 sm:scale-100",
  leave: "ease-in duration-200",
  leaveFrom: "opacity-100 translate-y-0 sm:scale-100",
  leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
};
</script>
