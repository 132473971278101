<i18n lang="json">
{
  "fr": {
    "close-sidebar": "Fermer le panneau",
    "logo": "Logo Vote pour ça"
  },
  "en": {
    "close-sidebar": "Close sidebar",
    "logo": "Vote pour ça logo"
  }
}
</i18n>

<template>
  <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
      <TransitionChild as="template" v-bind="overlayTransition">
        <DialogOverlay class="transition-opacity fixed inset-0 bg-grey-700/75" />
      </TransitionChild>
      <TransitionChild as="template" v-bind="slidingPanelTransition">
        <div class="relative flex-1 flex flex-col max-w-xs w-full bg-secondary-200">
          <TransitionChild as="template" v-bind="closeBtnTransition">
            <div class="absolute top-0 right-0 -mr-12 pt-3">
              <button
                type="button"
                class="text-grey-200 ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                @click="sidebarOpen = false">
                <span class="sr-only">{{ t("close-sidebar") }}</span>
                <PfIcon size="lg" fill-class="" stroke-class="stroke-current" :icon="CloseIcon" aria-hidden="true" />
              </button>
            </div>
          </TransitionChild>
          <div class="flex-1 h-0 pb-4 overflow-y-auto">
            <div class="flex-shrink-0 flex items-center px-4 h-16">
              <img class="h-5 relative top-1" :src="require('@/assets/img/vpc-logo.svg')" :alt="t('logo')" />
            </div>
            <MainMenu />
          </div>
          <div class="flex-shrink-0 flex flex-col items-start border-t border-grey-200 p-4">
            <LangSwitch class="mb-4" />
            <LogoutBtn />
          </div>
        </div>
      </TransitionChild>
      <div class="flex-shrink-0 w-14">
        <!-- Force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Static sidebar for desktop -->
  <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
    <div class="flex-1 flex flex-col min-h-0 border-r border-grey-200 bg-secondary-200">
      <div class="flex-1 flex flex-col pb-8 overflow-y-auto">
        <div class="flex items-center flex-shrink-0 px-4 h-16">
          <img class="h-5 relative top-1" :src="require('@/assets/img/vpc-logo.svg')" :alt="t('logo')" />
        </div>
        <MainMenu />
      </div>
      <div class="flex-shrink-0 flex flex-col items-start border-t border-grey-200 p-4 pb-8">
        <LangSwitch class="mb-4" />
        <LogoutBtn />
      </div>
    </div>
  </div>

  <div class="md:pl-64 flex flex-col flex-1">
    <TopBar class="md:h-2" @click="sidebarOpen = true" />

    <!-- Main content -->
    <main class="flex-1">
      <Breadcrumb
        v-if="showBreadcrumbs"
        :breadcrumbs="props.breadcrumbs"
        class="mb-4 px-section md:px-8 border-b border-grey-200" />
      <div
        v-if="showTitle"
        class="px-section pt-6 pb-5 border-b border-grey-200 md:pt-0 md:px-8"
        :class="{ 'md:mt-14': !showBreadcrumbs }">
        <slot name="title"></slot>
      </div>
      <div class="px-section py-5 md:px-8 relative overflow-hidden min-h-96">
        <Loading :loading="loading">
          <slot></slot>
        </Loading>
      </div>
    </main>
  </div>
</template>

<script setup>
import { defineProps, ref, useSlots, computed } from "vue";
import { useI18n } from "vue-i18n";

import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from "@headlessui/vue";
import Breadcrumb from "@/components/app/breadcrumb";
import Loading from "@/components/app/loading";
import LogoutBtn from "@/components/app/logout-btn";
import LangSwitch from "@/components/app/lang-switch";
import MainMenu from "@/components/app/main-menu";
import TopBar from "@/components/app/top-bar";

import CloseIcon from "@/lib/icons/close.json";

const props = defineProps({
  breadcrumbs: { type: Array, default: null },
  loading: Boolean
});
const slots = useSlots();
const { t } = useI18n();

const sidebarOpen = ref(false);

const showTitle = computed(() => !!slots.title);
const showBreadcrumbs = computed(() => !!props.breadcrumbs);

// Style de transitions
const overlayTransition = {
  enter: "ease-linear duration-300",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "ease-linear duration-300",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0"
};

const closeBtnTransition = {
  enter: "ease-in-out duration-300",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "ease-in-out duration-300",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0"
};

const slidingPanelTransition = {
  enter: "transition ease-in-out duration-300 transform",
  enterFrom: "-translate-x-full",
  enterTo: "translate-x-0",
  leave: "transition ease-in-out duration-300 transform",
  leaveFrom: "translate-x-0",
  leaveTo: "-translate-x-full"
};
</script>
