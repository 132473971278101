import { defineStore } from "pinia";
import { USER_TYPE_USER, USER_TYPE_ADMIN, USER_TYPE_EDITOR, USER_TYPE_ANONYMOUS } from "@/lib/consts/enums";
import { CLAIM_UTYPE, CLAIM_UTYPE_USER, CLAIM_UTYPE_EDITOR, CLAIM_UTYPE_ADMIN } from "@/lib/consts/claims";

type AuthState = {
  claims: Record<string, string> | null;
  initialized: boolean;
};

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    claims: null,
    initialized: false
  }),
  actions: {
    setClaims(claims: Record<string, string> | null) {
      this.$patch((state) => {
        state.initialized = true;
        state.claims = claims;
      });
    }
  },
  getters: {
    isLoggedIn(state) {
      return !!state.claims;
    },
    userType(state) {
      const utype = state.claims == null ? null : state.claims[CLAIM_UTYPE];

      switch (utype) {
        case CLAIM_UTYPE_ADMIN:
          return USER_TYPE_ADMIN;
        case CLAIM_UTYPE_EDITOR:
          return USER_TYPE_EDITOR;
        case CLAIM_UTYPE_USER:
          return USER_TYPE_USER;
      }

      return USER_TYPE_ANONYMOUS;
    }
  }
});
