<i18n lang="json">
{
  "fr": {
    "edit-button-label": "Modifier",
    "confirm-edit-modal-title": "Êtes-vous sûr de vouloir modifier cet élément?"
  },
  "en": {
    "edit-button-label": "Edit",
    "confirm-edit-modal-title": "Are you sure you want to edit this item?"
  }
}
</i18n>

<template>
  <div ref="editableComponent" class="flex items-start flex-grow w-full">
    <slot name="field" :isReadonly="isReadonly"></slot>
    <div v-if="isReadonly" class="ml-3">
      <!-- Hack: element takes same place as pf-field label -->
      <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
      <span class="block font-medium text-sm mb-1 opacity-0" aria-hidden="true">.</span>
      <PfButtonAction
        is-icon-only
        btn-style="outline"
        :icon="PENCIL_ICON"
        :screen-reader-addon="t('edit-button-label')"
        @click="() => confirmEditModal.openModal()" />
    </div>

    <UiDialogConfirmModal
      ref="confirmEditModal"
      :title="props.confirmEditModalTitle || t('confirm-edit-modal-title')"
      :description="props.confirmEditModalDescription"
      @confirm="onEdit" />
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { ref, defineProps } from "vue";

import PENCIL_ICON from "@/lib/icons/pencil.json";

const { t } = useI18n();

const props = defineProps({
  confirmEditModalTitle: { type: String, default: "" },
  confirmEditModalDescription: { type: String, default: "" },
  isAlwaysActive: Boolean
});

const isReadonly = ref(props.isAlwaysActive ? false : true);
const confirmEditModal = ref(null);
const editableComponent = ref(null);

function giveFocusToInput() {
  // Wait for modal to finish its transition before setting focus on input
  setTimeout(() => {
    // Give focus to first child of type "input"
    editableComponent.value.querySelector("input").focus();
  }, 310);
}

function onEdit() {
  isReadonly.value = false;
  giveFocusToInput();
}
</script>
