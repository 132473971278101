<template>
  <div class="pf-form-section pt-10 first:pt-0">
    <!-- Section head -->
    <slot name="header">
      <div v-if="title || description" class="mb-6">
        <component
          :is="titleTag"
          v-if="title"
          class="text-lg leading-6 font-medium text-grey-900 dark:text-grey-300 my-0"
          :aria-describedby="uniqueId">
          {{ title }}
        </component>
        <p v-if="description" :id="uniqueId" class="mt-1 text-sm text-grey-500 dark:text-grey-400">
          {{ description }}
        </p>
      </div>
    </slot>
    <!-- Section body -->
    <div class="grid grid-cols-1 gap-y-6 gap-x-4" :class="{ 'sm:grid-cols-12': isGrid }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
let instanceCounter = 0;
export default {
  props: {
    isGrid: Boolean,
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    titleTag: {
      type: String,
      default: "h3",
      validator(value) {
        return ["h2", "h3", "h4", "h5", "h6"].includes(value);
      }
    }
  },
  data() {
    return {
      uniqueId: `pf-section-desc-${++instanceCounter}`
    };
  }
};
</script>
