export const URL_ROOT = "root-url";

export const URL_ACCOUNT_LOGIN = "account-login";
export const URL_ACCOUNT_CREATE = "account-create";
export const URL_ACCOUNT_CONFIRM = "confirm-user-url";
export const URL_ACCOUNT_FORGOT_PASSWORD = "forgot-password-url";
export const URL_ACCOUNT_RESET_PASSWORD = "reset-password-url";

export const URL_QUESTIONNAIRE_LIST = "questionnaire-list";
export const URL_QUESTIONNAIRE_DETAILS = "questionnaire-details";
export const URL_QUESTIONNAIRE_IMPORT = "questionnaire-import";
export const URL_QUESTIONNAIRE_CREATE = "questionnaire-create";
export const URL_QUESTIONNAIRE_EDIT = "questionnaire-edit";

export const URL_CONSULTATION_INDEX = "consultation-index";
export const URL_CONSULTATION_CONFIRMATION = "consultation-confirmation";

export const URL_REPORT_LIST = "report-list";
export const URL_REPORT_CREATE = "report-create";
export const URL_REPORT_EDIT = "report-edit";
export const URL_REPORT_VIEW = "report-view";
