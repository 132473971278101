<i18n lang="json">
{
  "fr": {
    "questionnaire": "Questionnaires",
    "reports": "Rapports"
  },
  "en": {
    "questionnaire": "Questionnaires",
    "reports": "Reports"
  }
}
</i18n>

<template>
  <nav class="px-2 space-y-1">
    <MainMenuLink v-bind="$attrs" :to="{ name: $consts.urls.URL_QUESTIONNAIRE_LIST }" :icon="QUESTION_ICON">
      {{ t("questionnaire") }}
    </MainMenuLink>
    <MainMenuLink v-bind="$attrs" :to="{ name: $consts.urls.URL_REPORT_LIST }" :icon="GRAPH_ICON">
      {{ t("reports") }}
    </MainMenuLink>
  </nav>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import MainMenuLink from "./main-menu-link";
import QUESTION_ICON from "@/lib/icons/question-mark-circle.json";
import GRAPH_ICON from "@/lib/icons/chart-bar.json";

const { t } = useI18n();
</script>
