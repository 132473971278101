import { configure, defineRule } from "vee-validate";
import i18n from "@/lib/i18n";

// Rules
import { required, between, email, numeric, digits, image, url, confirmed, regex } from "@vee-validate/rules";

// All rules options and more built-in rules
// https://vee-validate.logaretm.com/v4/guide/global-validators#available-rules
defineRule("required", required);
defineRule("between", between);
defineRule("email", email);
defineRule("numeric", numeric);
defineRule("digits", digits);
defineRule("image", image);
defineRule("url", url);
defineRule("dateMin", (value, [target]) => value >= target);
defineRule("password", (value) =>
  regex(value, { regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~*])(?=.{10,})/ })
);
defineRule("samePassword", confirmed);
defineRule("requiredHtml", (value) => {
  // Note : Ça ne gère pas les &nbsp; à revoir plus tard
  const regexp = /<[^/>][^>]*><\/[^>]+>/gi;
  return value.replace(regexp, "").trim() !== "";
});
defineRule("requiredIf", (value, [target]) => {
  if (required(target)) return required(value);

  return true;
});

// Error messages configuration
// Default error messages extracted here
// https://github.com/logaretm/vee-validate/blob/main/packages/i18n/src/locale/en.json"
// https://github.com/logaretm/vee-validate/blob/main/packages/i18n/src/locale/fr.json"
configure({
  generateMessage: (context) => {
    const params = (context.rule.params || []).reduce((params, value, index) => ({ ...params, [`p${index}`]: value }), {
      field: context.field
    });

    return i18n.global.t(`validator-${context.rule.name}`, params);
  }
});
