export const USER_TYPE_ANONYMOUS = "ANONYMOUS";
export const USER_TYPE_ADMIN = "ADMIN";
export const USER_TYPE_EDITOR = "EDITOR";
export const USER_TYPE_USER = "USER";

export const QUESTIONNAIRE_ITEM_TYPE_HEADING2 = "HEADING2";
export const QUESTIONNAIRE_ITEM_TYPE_HEADING3 = "HEADING3";
export const QUESTIONNAIRE_ITEM_TYPE_HEADING4 = "HEADING4";
export const QUESTIONNAIRE_ITEM_TYPE_HEADING5 = "HEADING5";
export const QUESTIONNAIRE_ITEM_TYPE_PARAGRAPH = "PARAGRAPH";
export const QUESTIONNAIRE_ITEM_TYPE_NOTE = "NOTE";
export const QUESTIONNAIRE_ITEM_TYPE_TEXTBOX = "TEXTBOX";
export const QUESTIONNAIRE_ITEM_TYPE_TEXTAREA = "TEXTAREA";
export const QUESTIONNAIRE_ITEM_TYPE_CHOICE = "CHOICE";
export const QUESTIONNAIRE_ITEM_TYPE_SCALE = "SCALE";
export const QUESTIONNAIRE_ITEM_TYPE_CHECKBOX_LIST = "CHECKBOX_LIST";
export const QUESTIONNAIRE_ITEM_TYPE_CHECKBOX_LIST_HEAD = "CHECKBOX_LIST_HEAD";
export const QUESTIONNAIRE_ITEM_TYPE_CHECKBOX_LIST_LINE = "CHECKBOX_LIST_LINE";
export const QUESTIONNAIRE_ITEM_TYPE_CHOICE_GRID = "CHOICE_GRID";
export const QUESTIONNAIRE_ITEM_TYPE_CHOICE_GRID_HEAD = "CHOICE_GRID_HEAD";
export const QUESTIONNAIRE_ITEM_TYPE_CHOICE_GRID_LINE = "CHOICE_GRID_LINE";

export const QUESTIONNAIRE_STATUS_PARTIAL = "PARTIAL";
export const QUESTIONNAIRE_STATUS_COMPLETE = "COMPLETE";

export const CONSULTATION_CONTEXT_TYPE_PUBLIC = "PUBLIC";
export const CONSULTATION_CONTEXT_TYPE_ONSITE = "ON_SITE";

export const BLOCK_TYPE_TITLE = "TITLE";
export const BLOCK_TYPE_PARAGRAPH = "PARAGRAPH";
export const BLOCK_TYPE_COMMENT = "COMMENT";
export const BLOCK_TYPE_PAGE_BREAK = "PAGE_BREAK";
export const BLOCK_TYPE_IMAGE_CHART = "IMAGE_CHART";
export const BLOCK_TYPE_BAR = "BAR";
export const BLOCK_TYPE_MULTIPLE_BARS = "MULTIPLE_BARS";
export const BLOCK_TYPE_PIE = "PIE";
export const BLOCK_TYPE_RADAR = "RADAR";

export const BAR_STYLE_SIMPLE = "BAR";
export const BAR_STYLE_STACKED = "STACKED_BAR";
export const PIE_STYLE_PIE = "PIE";
export const PIE_STYLE_DONUT = "DONUT";
export const PIE_STYLE_POLAR = "POLAR";
export const MULTIPLE_BARS_STYLE_GROUPED = "GROUPED_BARS";
export const MULTIPLE_BARS_STYLE_STACKED = "STACKED_BARS";

export const DATA_SOURCE_MANUAL = "MANUAL";
export const DATA_SOURCE_QUESTION = "QUESTION";
