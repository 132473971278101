import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client/core";

import { VUE_APP_GRAPHQL_HTTP } from "@/env";

import possibleTypes from "./possibleTypes.json";

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: VUE_APP_GRAPHQL_HTTP
});

// To remove Apollo warnings when merging arrays
// https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays
const replaceWithIncoming = (existing, incoming) => incoming;

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    ReportGraphType: {
      fields: {
        blocks: {
          merge: replaceWithIncoming
        },
        accessKeys: {
          merge: replaceWithIncoming
        }
      }
    }
  }
});

const defaultOptions = {
  query: {
    fetchPolicy: "network-only"
  },
  watchQuery: {
    fetchPolicy: "cache-and-network"
  }
};

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
  defaultOptions
});
