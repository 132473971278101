import { createRouter, createWebHistory } from "vue-router";
import { URL_ACCOUNT_LOGIN, URL_ROOT } from "@/lib/consts/urls";

import routes from "./routes";
import { useAuthStore } from "@/lib/store/auth";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const auth = useAuthStore();

  // Enforce logged-in user except for anonymous routes
  if (to.matched.some((r) => !r.meta.anonymous)) {
    await waitUntilAuthIsInitialized();

    if (!auth.isLoggedIn) {
      return next({
        name: URL_ACCOUNT_LOGIN,
        query: {
          returnPath: to.fullPath
        }
      });
    }
  } else if (to.matched.some((r) => r.meta.notConnected)) {
    await waitUntilAuthIsInitialized();

    if (auth.isLoggedIn) {
      return next({
        name: URL_ROOT
      });
    }
  }

  // Enforce usertype constraints
  for (const match of to.matched) {
    if (match.meta.usertype && auth.userType !== match.meta.usertype) {
      return next({
        name: URL_ROOT
      });
    }
  }

  next();
});

function waitUntilAuthIsInitialized() {
  const authStore = useAuthStore();

  return new Promise((resolve) => {
    if (authStore.initialized) resolve();

    const unsubscribe = authStore.$onAction(({ store, after }) => {
      after(() => {
        if (store.initialized) {
          resolve();
          unsubscribe();
        }
      });
    });
  });
}

export default router;
