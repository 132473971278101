<i18n lang="json">
{
  "fr": {
    "published": "Publié",
    "not-published": "Non publié",
    "archived": "Archivé"
  },
  "en": {
    "published": "Published",
    "not-published": "Not published",
    "archived": "Archived"
  }
}
</i18n>

<template>
  <span class="ui-tag" :class="badgeColor">{{ badgeLabel }}</span>
</template>

<script setup>
import { computed, defineProps } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps({ published: Boolean, archived: Boolean });

const badgeColor = computed(() => {
  if (props.archived) return "bg-grey-200 text-grey-900";
  else if (props.published) return "bg-emerald-100 text-emerald-800";
  else return "bg-red-100 text-red-800";
});
const badgeLabel = computed(() => {
  if (props.archived) return t("archived");
  else if (props.published) return t("published");
  else return t("not-published");
});
</script>
