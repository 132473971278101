<template>
  <label
    :for="inputId"
    class="block text-sm font-medium transition-colors duration-200 ease-in-out"
    :class="{
      'text-red-500': hasErrorState,
      'text-grey-500 dark:text-grey-400': disabled,
      'text-grey-700 dark:text-grey-300': !hasErrorState && !disabled
    }">
    <slot>{{ label }}</slot>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    inputId: {
      type: String,
      default: ""
    },
    hasErrorState: Boolean,
    disabled: Boolean
  }
};
</script>
