<template>
  <PfButtonAction
    v-if="locale !== $consts.langs.LANG_FR"
    class="text-grey-600 hover:text-grey-900 focus:text-grey-900"
    btn-style="link"
    label="Français"
    @click="setLocale($consts.langs.LANG_FR)" />
  <PfButtonAction
    v-else-if="locale !== $consts.langs.LANG_EN"
    class="text-grey-600 hover:text-grey-900 focus:text-grey-900"
    btn-style="link"
    label="English"
    @click="setLocale($consts.langs.LANG_EN)" />
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { setLocale } from "@/lib/i18n";

const { locale } = useI18n();
</script>
