<i18n lang="json">
{
  "fr": {
    "confirm-button-label": "Confirmer",
    "cancel-button-label": "Annuler"
  },
  "en": {
    "confirm-button-label": "Confirm",
    "cancel-button-label": "Cancel"
  }
}
</i18n>

<template>
  <UiDialogModal ref="modal" :title="props.title" :description="props.description" :icon="props.icon ?? EXCLAMATION_ICON">
    <template #actions="{ closeModal }">
      <PfButtonAction
        btn-style="outline"
        class="w-full text-grey-700 border-grey-700 hover:text-emerald-600 focus:text-emerald-600 hover:border-emerald-600 focus:border-emerald-600"
        :label="props.cancelButtonLabel ?? defaultCancelLabel"
        @click="cancel(closeModal)" />
      <PfButtonAction
        class="w-full bg-emerald-500 hover:bg-emerald-600 focus:bg-emerald-600"
        :label="props.confirmButtonLabel ?? defaultConfirmLabel"
        @click="confirm(closeModal)" />
    </template>
  </UiDialogModal>
</template>

<script setup>
import { defineProps, defineEmits, defineExpose, ref } from "vue";
import { useI18n } from "vue-i18n";
import EXCLAMATION_ICON from "@/lib/icons/exclamation-circle.json";

const { t } = useI18n();
const defaultConfirmLabel = t("confirm-button-label");
const defaultCancelLabel = t("cancel-button-label");

const props = defineProps({
  title: { type: String, default: null },
  description: { type: String, default: null },
  confirmButtonLabel: { type: String, default: null },
  cancelButtonLabel: { type: String, default: null },
  icon: { type: Object, default: null }
});

const modal = ref(null);
defineExpose({
  closeModal: () => modal.value.closeModal(),
  openModal: () => modal.value.openModal(),
  isOpen: () => modal.value.isOpen()
});

// Événements
const emit = defineEmits(["confirm", "cancel"]);
const confirm = (closeModalFn) => {
  emit("confirm");
  closeModalFn();
};
const cancel = (closeModalFn) => {
  emit("cancel");
  closeModalFn();
};
</script>
