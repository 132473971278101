import script from "./color-picker.vue?vue&type=script&setup=true&lang=js"
export * from "./color-picker.vue?vue&type=script&setup=true&lang=js"

import "./color-picker.vue?vue&type=style&index=0&id=3ccd90a0&lang=postcss"
/* custom blocks */
import block0 from "./color-picker.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__