<template>
  <span
    v-show="showTag"
    class="pf-tag inline-flex items-center py-0.5 font-medium text-p4"
    :class="[bgColorClass, isDarkTheme ? 'text-white' : 'text-grey-900', isSquared ? 'rounded px-2' : 'rounded-full px-2.5']">
    {{ label }}
    <button
      v-if="canDismiss"
      type="button"
      class="shrink-0 ml-2 relative h-extend-cursor-area rounded-full inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-colors duration-200 ease-in-out"
      :class="
        isDarkTheme
          ? 'text-white hover:text-grey-500 focus:text-grey-500'
          : 'text-grey-900 hover:text-grey-700 focus:text-grey-700'
      "
      @click="
        $emit('dismiss');
        showTag = false;
      ">
      <span class="sr-only">{{ dismissLabel }}</span>
      <Icon :icon="ICON_CLOSE" size="xxs" aria-hidden="true" />
    </button>
  </span>
</template>

<script>
import ICON_CLOSE from "../icons/close.json";
import Icon from "./icon";

export default {
  components: {
    Icon
  },
  props: {
    bgColorClass: {
      type: String,
      default: "bg-white"
    },
    label: {
      type: String,
      default: ""
    },
    dismissLabel: {
      type: String,
      default: ""
    },
    isDarkTheme: Boolean,
    canDismiss: Boolean,
    isSquared: Boolean
  },
  data() {
    return {
      ICON_CLOSE,
      showTag: true
    };
  }
};
</script>
