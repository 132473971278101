<template>
  <div :class="props.class"></div>
</template>

<script setup>
import { defineProps, onMounted } from "vue";

const cdn = "//s7.addthis.com/js/300/addthis_widget.js";
const publicId = "62aa8337f792b0a9";

const props = defineProps({
  class: {
    type: String,
    // Par défaut cet élément va afficher les boutons de partage, mais on peut changer ça si on veut autre chose
    default: "addthis_inline_share_toolbox"
  }
});

onMounted(() => {
  // Si le script est déjà loadé, alors on demande à AddThis de rafraichir
  if (document.getElementById("addthis-share") !== null) {
    return window.addthis && window.addthis.layers && window.addthis.layers.refresh();
  }

  // Injecter le script d'AddThis
  const el = document.createElement("script");
  el.setAttribute("id", "addthis-share");
  el.setAttribute("src", `${cdn}#pubid=${publicId}`);
  el.setAttribute("async", true);
  document.body.appendChild(el);
});
</script>
