<template>
  <div class="pf-form-layout space-y-8 divide-y divide-grey-300 dark:divide-grey-800">
    <!-- Form body -->
    <div class="space-y-8 divide-y divide-grey-300 dark:divide-grey-800">
      <slot></slot>
    </div>

    <!-- Form footer -->
    <slot name="footer">
      <div v-if="hasFooter" class="pt-5">
        <div class="flex items-center justify-end">
          <Spinner v-if="processing" :loading-label="loadingLabel" class="mr-3" is-small />
          <ButtonAction
            v-if="canCancel"
            btn-type="button"
            class="mr-4"
            btn-style="outline"
            :label="cancelLabel"
            :is-disabled="processing"
            @click="$emit('cancel')" />
          <ButtonAction btn-type="submit" :is-disabled="disableSubmit || processing" :label="submitLabel" />
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import ButtonAction from "../button/action";
import Spinner from "../spinner";

export default {
  components: {
    ButtonAction,
    Spinner
  },
  props: {
    hasFooter: Boolean,
    submitLabel: {
      type: String,
      default: "Sauvegarder"
    },
    cancelLabel: {
      type: String,
      default: "Annuler"
    },
    loadingLabel: {
      type: String,
      default: "En chargement..."
    },
    canCancel: Boolean,
    disableSubmit: Boolean,
    processing: Boolean
  }
};
</script>
