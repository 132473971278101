export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page introuvable"])},
        "go-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à l'accueil"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
        "go-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to home page"])}
      }
    }
  })
}
