<template>
  <a
    v-if="isExternalLink"
    v-bind="$attrs"
    :href="props.to"
    target="_blank"
    class="flex items-center px-2 py-2 text-base font-medium rounded-md transition-colors duration-200 ease-in-out"
    :class="inactiveClass">
    <PfIcon v-if="icon" class="mr-3 flex-shrink-0" :icon="props.icon" fill-class="" stroke-class="stroke-current" />
    <slot />
  </a>
  <RouterLink v-else v-slot="{ isActive, href, navigate }" v-bind="props" custom>
    <a
      v-bind="$attrs"
      :href="href"
      class="flex items-center px-2 py-2 text-base font-medium rounded-md transition-colors duration-200 ease-in-out"
      :class="isActive ? activeClass : inactiveClass"
      @click="navigate">
      <PfIcon v-if="icon" class="mr-3 flex-shrink-0" :icon="props.icon" fill-class="" stroke-class="stroke-current" />
      <slot />
    </a>
  </RouterLink>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { RouterLink } from "vue-router";

const inactiveClass = "text-emerald-500 hover:text-emerald-600 focus:text-emerald-600 hover:bg-white focus:bg-white";
const activeClass = "bg-white text-emerald-600 hover:text-emerald-600 focus:text-emerald-600 cursor-default";

const props = defineProps({
  ...RouterLink.props,
  icon: {
    type: Object,
    default() {
      return null;
    }
  }
});

const isExternalLink = computed(() => {
  return typeof props.to === "string" && props.to.startsWith("http");
});
</script>
<script>
export default {
  inheritAttrs: false
};
</script>
