<i18n lang="json">
{
  "fr": {
    "refresh": "Rafraîchir",
    "unavailable": "Désolé, l'application rencontre présentement des problèmes. Nous travaillons à régler la situation.",
    "out-of-date": "Une nouvelle version de l'application est disponible."
  },
  "en": {
    "refresh": "Refresh",
    "unavailable": "We are sorry, the app is currently experiencing some issues. We are working to fix it.",
    "out-of-date": "A new version of the app is available."
  }
}
</i18n>

<template>
  <div>
    <p v-if="isUnavailable">
      {{ t("unavailable") }}
    </p>
    <p v-else-if="isOutOfDate">
      {{ t("out-of-date") }}
      <button data-test-id="refresh" @click="refresh">{{ t("refresh") }}</button>
    </p>
  </div>
</template>

<script setup>
import { Client } from "@/lib/helpers/client";
import { computed, ref, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const REFRESH_INTERVAL_MS = 60_000;

checkStatus();
const interval = setInterval(checkStatus, REFRESH_INTERVAL_MS);

const status = ref();
const initialVersion = ref();
const error = ref();

const isOutOfDate = computed(() => status.value && initialVersion.value !== status.value.version);
const isUnavailable = computed(() => error.value || (status.value && !status.value.ready));

onUnmounted(stop);

async function checkStatus() {
  try {
    const response = await Client.get(`/status`);

    status.value = await response.data;
    error.value = null;

    if (!initialVersion.value) {
      initialVersion.value = status.value.version;
    } else if (isOutOfDate.value) {
      stop();
    }
  } catch (err) {
    error.value = err;
  }
}

function stop() {
  clearInterval(interval);
}

function refresh() {
  window.location.reload();
}
</script>
