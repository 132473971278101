<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <component :is="is" v-if="content" v-html="content"></component>
</template>

<script setup>
import { defineProps, computed } from "vue";

var content = computed(() => (props.asPlainText ? props.value.plainText : props.value.html));

const props = defineProps({
  is: { type: String, default: "div" },
  asPlainText: { type: Boolean, default: false },
  value: { type: Object, required: true }
});
</script>
