<i18n lang="json">
{
  "fr": {
    "color": "Couleur (hex)"
  },
  "en": {
    "color": "Color (hex)"
  }
}
</i18n>

<template>
  <PfFormInputText
    :id="props.id"
    :value="hexColor"
    :label="t('color')"
    :leading-icon="leadingIcon"
    :leading-icon-color-class="leadingIconColorClass"
    maxlength="7"
    @input="(e) => updateFormattedColor(e.target.value)" />
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  id: { type: String, required: true },
  color: { type: [Object, String], default: "" },
  leadingIcon: { type: Object, default: null },
  leadingIconColorClass: { type: String, default: "" }
});

const hexColor = computed(() => {
  if (typeof props.color === "object") return props.color.hex;
  return "";
});

const emit = defineEmits(["onColorChange"]);

const updateFormattedColor = (hex) => {
  let cleanedHex = hex;

  if (!hex.startsWith("#")) cleanedHex = `#${hex}`;
  if (cleanedHex.length !== 7) return;

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(cleanedHex);
  let rgb = result ? `${parseInt(result[1], 16)} ${parseInt(result[2], 16)} ${parseInt(result[3], 16)}` : null;

  emit("onColorChange", { hex: cleanedHex, rgb: rgb });
};
</script>
