<template>
  <button
    class="pf-button"
    :class="[
      btnStyleClass,
      sizeClass,
      transition ? `pf-transition-${transition.name} pf-transition-${transition.name}--${transition.color}` : '',
      { 'pf-button--grouped': isGrouped }
    ]"
    :type="btnType"
    :disabled="isDisabled">
    <ButtonBg :transition="transition" />
    <ButtonContent v-bind="$props" />
  </button>
</template>

<script>
import ButtonBg from "./_background";
import ButtonContent from "./_content";
import { commonBtnProps } from "./commonBtnProps";

export default {
  components: {
    ButtonBg,
    ButtonContent
  },
  props: {
    ...commonBtnProps,
    btnType: {
      type: String,
      default: "button"
    }
  },
  computed: {
    sizeClass() {
      if (this.size === "sm") {
        return "pf-button--sm";
      } else if (this.size === "lg") {
        return "pf-button--lg";
      } else if (this.isIconOnly) {
        return "pf-button--icon";
      }
      return "";
    },
    btnStyleClass() {
      switch (this.btnStyle) {
        case "primary":
          return "pf-button--primary";
        case "secondary":
          return "pf-button--secondary";
        case "outline":
          return "pf-button--outline";
        case "link":
          return "pf-button--link";
        default:
          return this.btnStyle;
      }
    }
  }
};
</script>
