<template>
  <FormField
    :id="id"
    v-slot="{ hasErrorState }"
    :label="label"
    :description="description"
    :col-span-class="colSpanClass"
    :errors="errors"
    :disabled="disabled"
    :has-hidden-label="hasHiddenLabel">
    <textarea
      :id="id"
      :name="name"
      :value="value"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :rows="rows"
      class="shadow-sm block w-full sm:text-sm border rounded-md transition-colors duration-200 ease-in-out disabled:bg-grey-100 disabled:text-grey-700 dark:bg-grey-900 dark:disabled:bg-grey-700 dark:disabled:text-grey-300 read-only:bg-grey-100 dark:read-only:bg-grey-700 read-only:focus:ring-grey-500 read-only:focus:border-grey-500"
      :class="
        hasErrorState
          ? 'text-red-900 border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500 dark:text-red-400 dark:border-red-800 dark:placeholder-red-600'
          : 'text-grey-900 border-grey-300 focus:ring-primary-500 focus:border-primary-500 placeholder-grey-500 dark:text-grey-200 dark:border-grey-700 dark:placeholder-grey-400'
      "
      :aria-label="hasHiddenLabel ? label : null"
      :aria-invalid="hasErrorState"
      :aria-errormessage="hasErrorState ? `${id}-error` : null"
      :aria-describedby="description ? `${id}-description` : null"></textarea>
  </FormField>
</template>

<script>
import FormField, { commonFieldProps } from "../field/index";

export default {
  components: {
    FormField
  },
  props: {
    ...commonFieldProps,
    value: {
      type: [String, Number],
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    rows: {
      type: Number,
      default: 3
    },
    hasHiddenLabel: Boolean
  }
};
</script>
