export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié"])},
        "not-published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non publié"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivé"])}
      },
      "en": {
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
        "not-published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not published"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])}
      }
    }
  })
}
